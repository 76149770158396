module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Insurance Podcast","short_name":"Digital Insurance Podcast","start_url":"/podcast/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/assets/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/favicon*"]}},
    },{
      plugin: require('../node_modules/@jonaspiela/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"pielaco.com","plausibleScript":"script.outbound-links.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
